import { Box, Card, Grid, Input, makeStyles, MenuItem, Select, Typography } from '@material-ui/core';
import React from 'react';

export interface PaymentMethodProps {
  id: string;
  name: string;
  isSelected?: boolean;
  image: string;
  onClick: (id: string) => void;
  setOVOID?: (value: string) => void;
  ovoID: string;
  setVirtualId?: (value: string) => void;
  virtualId: string;
}

export const PaymentMethod: React.FC<PaymentMethodProps> = ({
  id,
  image,
  onClick,
  isSelected,
  name,
  ovoID,
  setOVOID,
  setVirtualId,
  virtualId,
}) => {
  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <Card
        onClick={() => {
          onClick(id);
        }}
        className={isSelected ? classes.selected : classes.nonSelected}
      >
        <Grid container spacing={1} style={{ padding: 6, cursor: 'pointer' }} alignItems="center" alignContent="center">
          <Grid item xs={1}>
            <img alt="mom" style={{ width: '100%' }} src={image}></img>
          </Grid>
          <Grid item xs={10}>
            <Typography align="left" variant="body1" component="div">
              {name}
            </Typography>
          </Grid>
        </Grid>
      </Card>
      {id === 'OVO' && isSelected && (
        <Box mt={2}>
          <Typography align="left" variant="body1" component="div">
            Your OVO id:
          </Typography>
          <Input
            value={ovoID}
            onChange={(e) => (setOVOID ? setOVOID(e.target.value) : {})}
            fullWidth
            placeholder="Please type your OVO ID here"
          />
        </Box>
      )}

      {id === 'VA' && isSelected && (
        <Box mt={2}>
          <Typography align="left" variant="body1" component="div">
            Please select a Virtual Account provider
          </Typography>
          <Select
            onChange={(e) => (setVirtualId ? setVirtualId(e.target.value as string) : {})}
            fullWidth
            value={virtualId}
          >
            <MenuItem value={'0'}>Select one...</MenuItem>
            <MenuItem value={'VA_DOKU'}>ALTO, ATM Bersama, Prima (DOKU VA)</MenuItem>
            {/* <MenuItem value={'VA_MANDIRI'}>MANDIRI</MenuItem> */}
            <MenuItem value={'VA_BSI'}>BSI</MenuItem>
            <MenuItem value={'VA_BRI'}>BRI</MenuItem>
            <MenuItem value={'VA_BNI'}>BNI</MenuItem>
            <MenuItem value={'VA_CIMB'}>CIMB</MenuItem>
            <MenuItem value={'VA_PERMATA'}>PERMATA</MenuItem>
            <MenuItem value={'VA_DANAMON'}>DANAMON</MenuItem>
          </Select>
        </Box>
      )}
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  selected: {
    padding: 2,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000',
    backgroundColor: '#ececec',
  },
  nonSelected: {
    padding: 2,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#fff',
  },
}));

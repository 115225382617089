import { createStore, createTypedHooks } from 'easy-peasy';
import { localeStore, LocaleStore } from './LocaleStore';

export interface StoreModel {
  locale: LocaleStore;
}

const typedStore: StoreModel = {
  locale: localeStore,
};

const { useStoreActions, useStoreState, useStoreDispatch } = createTypedHooks<StoreModel>();

export { useStoreActions, useStoreDispatch, useStoreState };

const store = createStore(typedStore);

export default store;

import i18n from 'i18n-js';
import memoize from 'lodash.memoize';

export const languages = ['en', 'id'];

export const DEFAULT_LANGUAGE = 'id';

interface GetterIndex {
  [key: string]: () => object;
}
const translationGetters: GetterIndex = {
  en: () => require('../translations/en.json'),
  id: () => require('../translations/id.json'),
};

export const getI18nConfig = (languageCode: string) => {
  const fallback = DEFAULT_LANGUAGE;
  const languageTag = languageCode && languages.includes(languageCode) ? languageCode : fallback;

  if (translateWithConfig.cache.clear) {
    translateWithConfig.cache.clear();
  }

  i18n.translations = { [languageTag]: translationGetters[languageTag]() };
  i18n.locale = languageTag;
};
export const translate = (key: string, languageCode: string): string => {
  const lan = getI18nConfig(languageCode);
  return translateWithConfig(key, lan);
};
export const translateWithConfig = memoize(
  (key, config) => i18n.t(key, config),
  (key, config) => (config ? key + JSON.stringify(config) : key),
);

import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { LoginLayout } from '../components/layouts/PaymentLayout';
import { TransactionStatusPage } from '../pages/TransactionStatus/TransactionStatus';
import { translate } from '../utils/Translate';

export const Home: React.FC = () => <div>{translate('pages.home.maintext', 'en')}</div>;

export const AppRouter: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/status/:reference" component={TransactionStatusPage} />
        <Route path="/:reference/lang/:language" component={LoginLayout} />
        <Route path="/:reference/:status" component={LoginLayout} />
        <Route path="/:reference/" component={LoginLayout} />
      </Switch>
    </Router>
  );
};

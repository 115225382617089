import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
  Upload: any;
};

export enum App_Type {
  Android = 'ANDROID',
  Ios = 'IOS'
}

export type Address = {
  __typename?: 'Address';
  id: Scalars['Int'];
  street: Scalars['String'];
  city: Scalars['String'];
  zipCode: Scalars['String'];
  country: Scalars['String'];
  client: Client;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type AddressDataInput = {
  street: Scalars['String'];
  city: Scalars['String'];
  zipCode: Scalars['String'];
  country: Scalars['String'];
};

export type AdminGetInvoicesResponse = {
  __typename?: 'AdminGetInvoicesResponse';
  invoices: Array<Invoice>;
};

export type AdminGetPRsResponse = {
  __typename?: 'AdminGetPRsResponse';
  PRs: Array<PaymentRequest>;
};

export type AdminGetUsersResponse = {
  __typename?: 'AdminGetUsersResponse';
  users: Array<AdminUserResponseLoad>;
};

export type AdminUserResponseLoad = {
  __typename?: 'AdminUserResponseLoad';
  id: Scalars['Int'];
  email: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companyType: Company_Type;
  referralId: Scalars['String'];
  merchants: Array<Maybe<Merchant>>;
  invoiceCount: Scalars['Int'];
  paymentCount: Scalars['Int'];
  invoiceValue: Scalars['Float'];
  prValue: Scalars['Float'];
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  user: User;
  token: Scalars['String'];
  tokenExpire: Scalars['DateTime'];
};

export enum Bucket_Id {
  CompanyLogoBucket = 'COMPANY_LOGO_BUCKET',
  InvoiceBucket = 'INVOICE_BUCKET',
  ReceiptBucket = 'RECEIPT_BUCKET',
  VerificationBucket = 'VERIFICATION_BUCKET',
  ExpenseBucket = 'EXPENSE_BUCKET'
}

export type Bank = {
  __typename?: 'Bank';
  bank_swift?: Maybe<Scalars['String']>;
  bank_id?: Maybe<Scalars['String']>;
  bank_name?: Maybe<Scalars['String']>;
};

export enum Company_Type {
  Individual = 'INDIVIDUAL',
  Company = 'COMPANY'
}

export type Client = {
  __typename?: 'Client';
  id: Scalars['Int'];
  customerName: Scalars['String'];
  email: Scalars['String'];
  contactPerson: Scalars['String'];
  phoneNumber: Scalars['String'];
  website: Scalars['String'];
  discount: Scalars['Int'];
  notes: Scalars['String'];
  isFavorite: Scalars['Boolean'];
  address?: Maybe<Address>;
  user: User;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ClientDataInput = {
  customerName: Scalars['String'];
  email: Scalars['String'];
  contactPerson: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  isFavorite?: Maybe<Scalars['Boolean']>;
  address: AddressDataInput;
};

export type CompanyDataPayload = {
  __typename?: 'CompanyDataPayload';
  companyName?: Maybe<Scalars['String']>;
  companyLogo?: Maybe<Scalars['String']>;
};

export type CompatibleResult = {
  __typename?: 'CompatibleResult';
  isSuccess: Scalars['Boolean'];
  compatible?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

export type CreateClientResult = {
  __typename?: 'CreateClientResult';
  isSuccess: Scalars['Boolean'];
  client?: Maybe<Client>;
  message?: Maybe<Scalars['String']>;
};

export type CreateInvoiceResult = {
  __typename?: 'CreateInvoiceResult';
  isSuccess: Scalars['Boolean'];
  id?: Maybe<Scalars['Int']>;
  reference?: Maybe<Scalars['String']>;
  pdfUrl?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type Currency = {
  __typename?: 'Currency';
  id: Scalars['ID'];
  isoCode?: Maybe<Scalars['String']>;
  position?: Maybe<Fix_Type>;
  shortFormat?: Maybe<Scalars['String']>;
  thousandSeparator?: Maybe<Scalars['String']>;
  decimalSeparator?: Maybe<Scalars['String']>;
  decimalNumbers?: Maybe<Scalars['Int']>;
};


export type DetailedReportResult = {
  __typename?: 'DetailedReportResult';
  profit?: Maybe<Profit>;
  totalOperationCost?: Maybe<Scalars['Float']>;
  incomeBeforeTax?: Maybe<Scalars['Float']>;
  tax?: Maybe<Scalars['Float']>;
  msg?: Maybe<Scalars['String']>;
};

export enum Expense_Recurrence {
  None = 'NONE',
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY',
  Occasionally = 'OCCASIONALLY'
}

export enum Expense_Type {
  OtherExpense = 'OTHER_EXPENSE',
  CostsOfGoodsSold = 'COSTS_OF_GOODS_SOLD',
  Packaging = 'PACKAGING',
  Transportation = 'TRANSPORTATION',
  StaffSallary = 'STAFF_SALLARY',
  RentAndUtilities = 'RENT_AND_UTILITIES',
  FoodAndDrink = 'FOOD_AND_DRINK',
  Savings = 'SAVINGS'
}

export type Expense = {
  __typename?: 'Expense';
  id: Scalars['Int'];
  type: Expense_Type;
  user: User;
  expenseName: Scalars['String'];
  price: Scalars['Int'];
  imageIds: Scalars['JSON'];
  recurrence: Expense_Recurrence;
  deleted: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};

export type ExpenseImagesInput = {
  data: Scalars['String'];
  fileType?: Maybe<Scalars['String']>;
};

export type ExpenseInput = {
  price: Scalars['Int'];
  type?: Maybe<Expense_Type>;
  expenseName: Scalars['String'];
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurrence?: Maybe<Expense_Recurrence>;
  imageIds: Array<Scalars['Int']>;
};

export type ExpenseResult = {
  __typename?: 'ExpenseResult';
  isSuccess: Scalars['Boolean'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  expense?: Maybe<Expense>;
  message?: Maybe<Scalars['String']>;
};

export type ExpensesResult = {
  __typename?: 'ExpensesResult';
  isSuccess: Scalars['Boolean'];
  expenses?: Maybe<Array<Expense>>;
  message?: Maybe<Scalars['String']>;
};

export enum Fix_Type {
  Prefix = 'PREFIX',
  Suffix = 'SUFFIX'
}

export type File = {
  data: Scalars['String'];
  fileType: Scalars['String'];
};

export type FilePayload = {
  __typename?: 'FilePayload';
  url: Scalars['String'];
};

export type GetImagesResult = {
  __typename?: 'GetImagesResult';
  isSuccess: Scalars['Boolean'];
  images: Array<Image>;
};

export type GetInvoiceByReferenceResult = {
  __typename?: 'GetInvoiceByReferenceResult';
  isSuccess: Scalars['Boolean'];
  isFullFilled?: Maybe<Scalars['Boolean']>;
  receiptUrl?: Maybe<Scalars['String']>;
  voucherType: Voucher_Type;
  isVerified?: Maybe<Scalars['Boolean']>;
  invoiceData?: Maybe<InvoiceDataPayload>;
  companyData?: Maybe<CompanyDataPayload>;
};

export type GetLastTransactionsResult = {
  __typename?: 'GetLastTransactionsResult';
  isSuccess: Scalars['Boolean'];
  transactions?: Maybe<Array<GetTransactionType>>;
};

export type GetMerchantBalanceResult = {
  __typename?: 'GetMerchantBalanceResult';
  isSuccess: Scalars['Boolean'];
  amount?: Maybe<Scalars['Float']>;
};

export type GetMerchantDataResult = {
  __typename?: 'GetMerchantDataResult';
  personal_id?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  status_flag?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  merchant_id?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  wallet_id?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['Float']>;
  phone?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  bank_account?: Maybe<Scalars['String']>;
  bank_id?: Maybe<Scalars['String']>;
};

export type GetMerchantResult = {
  __typename?: 'GetMerchantResult';
  status: Merchant_Status;
  merchant?: Maybe<Merchant>;
  balance: Scalars['Float'];
};

export type GetTransactionResult = {
  __typename?: 'GetTransactionResult';
  status: Transaction_Status;
  receiptUrl: Scalars['String'];
  entityType: Payment_Type;
  entityNumber: Scalars['String'];
  entityUrl: Scalars['String'];
  entityReference: Scalars['String'];
};

export type GetTransactionType = {
  __typename?: 'GetTransactionType';
  id: Scalars['String'];
  reference: Scalars['String'];
  amount: Scalars['Float'];
  finalAmount: Scalars['Float'];
  type: Transaction_Type;
  elementId: Scalars['Int'];
  title: Scalars['String'];
  date: Scalars['DateTime'];
};

export enum Invoice_Status {
  Created = 'CREATED',
  Deleted = 'DELETED'
}

export type Image = {
  __typename?: 'Image';
  id: Scalars['Int'];
  url: Scalars['String'];
  user: User;
  name: Scalars['String'];
  deleted: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type Invoice = {
  __typename?: 'Invoice';
  id: Scalars['Int'];
  user: User;
  reference: Scalars['String'];
  client?: Maybe<Client>;
  clientId?: Maybe<Scalars['Int']>;
  clientName: Scalars['String'];
  clinetAddress: Scalars['String'];
  clientEmail: Scalars['String'];
  invoiceNumber: Scalars['String'];
  payTerm: Scalars['DateTime'];
  products: Array<InvoiceProduct>;
  taxPercent: Scalars['Float'];
  discountPercent: Scalars['Float'];
  comment: Scalars['String'];
  pdfUrl: Scalars['String'];
  subTotal: Scalars['Float'];
  tax: Scalars['Float'];
  fees: Scalars['Float'];
  total: Scalars['Float'];
  shipping: Scalars['Float'];
  status: Invoice_Status;
  currency: Currency;
  fulfilled: Scalars['Boolean'];
  includeFees: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};

export type InvoiceDataPayload = {
  __typename?: 'InvoiceDataPayload';
  invoiceUrl?: Maybe<Scalars['String']>;
  invoiceNumber?: Maybe<Scalars['String']>;
  totalAmount?: Maybe<Scalars['Float']>;
  isFeeEnabled?: Maybe<Scalars['Boolean']>;
};

export type InvoiceInput = {
  reference?: Maybe<Scalars['String']>;
  clientId: Scalars['Int'];
  invoiceNumber?: Maybe<Scalars['String']>;
  payTerm?: Maybe<Scalars['DateTime']>;
  products?: Maybe<Array<Maybe<InvoiceProductInput>>>;
  taxPercent?: Maybe<Scalars['Float']>;
  discountPercent?: Maybe<Scalars['Float']>;
  shipping?: Maybe<Scalars['Float']>;
  fulfilled?: Maybe<Scalars['Boolean']>;
  comment?: Maybe<Scalars['String']>;
  localInvoiceId?: Maybe<Scalars['String']>;
  invoiceLocalPath?: Maybe<Scalars['String']>;
  pdfUrl?: Maybe<Scalars['String']>;
  includeFees?: Maybe<Scalars['Boolean']>;
};

export type InvoiceNumberPayload = {
  __typename?: 'InvoiceNumberPayload';
  invoiceNumber?: Maybe<Scalars['String']>;
  invoiceCount?: Maybe<Scalars['Int']>;
};

export type InvoiceProduct = {
  __typename?: 'InvoiceProduct';
  id: Scalars['Int'];
  name: Scalars['String'];
  quantity: Scalars['Int'];
  price: Scalars['Int'];
  type: Product_Type;
  invoice: Invoice;
  product: Product;
  costOfProductsSold: Scalars['Int'];
  quantityUnit: QuantityUnit;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};

export type InvoiceProductInput = {
  name: Scalars['String'];
  quantity: Scalars['Int'];
  price: Scalars['Int'];
  type: Product_Type;
  costOfProductsSold: Scalars['Int'];
  productId: Scalars['Int'];
  quantityUnit: QuantityUnitInput;
};

export type Issue = {
  __typename?: 'Issue';
  id: Scalars['ID'];
  user: User;
  title?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type IssueInput = {
  title?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};


export type KeysInput = {
  key: Scalars['String'];
  secret: Scalars['String'];
};

export enum Language {
  Id = 'id',
  En = 'en'
}

export type LanguageResult = {
  __typename?: 'LanguageResult';
  isSuccess: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};

export enum Merchant_Status {
  Inavtive = 'INAVTIVE',
  Denied = 'DENIED',
  Approved = 'APPROVED',
  NotRegistered = 'NOT_REGISTERED'
}

export type MePayload = {
  __typename?: 'MePayload';
  user?: Maybe<User>;
  invoices?: Maybe<Array<Maybe<Invoice>>>;
  paymentRequests?: Maybe<Array<Maybe<PaymentRequest>>>;
  currency?: Maybe<Currency>;
  clients?: Maybe<Array<Maybe<Client>>>;
  products?: Maybe<Array<Maybe<Product>>>;
  compatible: Scalars['Boolean'];
  accounts?: Maybe<Array<Maybe<PayoutAccount>>>;
  referrals?: Maybe<Array<Referral>>;
  expenses: Array<Expense>;
  fixFee?: Maybe<Scalars['Int']>;
  percentFee?: Maybe<Scalars['Float']>;
  invoiceNumber?: Maybe<Scalars['Int']>;
};

export type Merchant = {
  __typename?: 'Merchant';
  id: Scalars['ID'];
  user: User;
  merchantId: Scalars['String'];
  status: Merchant_Status;
  verification?: Maybe<Verification>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type MerchantDataInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  country: Scalars['String'];
  city: Scalars['String'];
  zipCode: Scalars['String'];
  street: Scalars['String'];
  bankAccount?: Maybe<Scalars['String']>;
  bankId?: Maybe<Scalars['String']>;
  walletId?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  signUp: AuthPayload;
  logIn: AuthPayload;
  authWithSocial: AuthPayload;
  loginToken: Result;
  authWithToken: AuthPayload;
  updateUserData: Result;
  uploadCompanyLogo: Result;
  changeCurrency: Result;
  saveUserFCM: Result;
  removeUserFCM: Result;
  changeLanguage: LanguageResult;
  uploadImage: UploadImageResult;
  createInvoice: CreateInvoiceResult;
  deleteInvoice: Result;
  setFulfilled: Result;
  createIssue: Result;
  createVerification: Result;
  uploadVerificationImage: Result;
  startPayment: StartPaymentResult;
  startPayout: Result;
  updateMerchantData: Result;
  createPaymentRequest: PaymentRequestResult;
  setPaymentRequestFulfilled: Result;
  deletePaymentRequest: Result;
  createClient: CreateClientResult;
  updateClient: Result;
  deleteClient: Result;
  toggleFavorite: Result;
  createProduct: ProductResult;
  updateProduct: Result;
  deleteProduct: Result;
  createPayoutAccount: PayoutAccountResult;
  updatePayoutAccount: Result;
  deletePayoutAccount: Result;
  setDefaultPayoutAccount: Result;
  setMerchantStatus?: Maybe<Merchant>;
  setPayoutStatus?: Maybe<Result>;
  createReferral: Result;
  adminAuth: AuthPayload;
  createExpense: ExpenseResult;
  updateExpense: ExpenseResult;
  deleteExpense: Result;
};


export type MutationSignUpArgs = {
  companyName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  referralId?: Maybe<Scalars['String']>;
};


export type MutationLogInArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationAuthWithSocialArgs = {
  token?: Maybe<Scalars['String']>;
  socialType?: Maybe<Social_Type>;
  referralId?: Maybe<Scalars['String']>;
};


export type MutationLoginTokenArgs = {
  email: Scalars['String'];
};


export type MutationAuthWithTokenArgs = {
  token: Scalars['String'];
};


export type MutationUpdateUserDataArgs = {
  data?: Maybe<UpdateUserDataInput>;
};


export type MutationUploadCompanyLogoArgs = {
  logo: Scalars['String'];
  fileType: Scalars['String'];
};


export type MutationChangeCurrencyArgs = {
  id: Scalars['Int'];
};


export type MutationSaveUserFcmArgs = {
  token: Scalars['String'];
  appVersion: Scalars['String'];
  appType: App_Type;
};


export type MutationRemoveUserFcmArgs = {
  token: Scalars['String'];
};


export type MutationChangeLanguageArgs = {
  language: Language;
};


export type MutationUploadImageArgs = {
  data: File;
  name?: Maybe<Scalars['String']>;
  bucketId: Bucket_Id;
};


export type MutationCreateInvoiceArgs = {
  data: InvoiceInput;
  pdf: Scalars['String'];
};


export type MutationDeleteInvoiceArgs = {
  invoiceId: Scalars['Int'];
};


export type MutationSetFulfilledArgs = {
  invoiceId: Scalars['Int'];
};


export type MutationCreateIssueArgs = {
  title: Scalars['String'];
  message: Scalars['String'];
};


export type MutationCreateVerificationArgs = {
  employees?: Maybe<Scalars['Int']>;
  websiteLink?: Maybe<Scalars['String']>;
  businessDescription: Scalars['String'];
  socialMediaLink?: Maybe<Scalars['String']>;
};


export type MutationUploadVerificationImageArgs = {
  data: File;
  imageKey: Scalars['String'];
  verificationId: Scalars['Int'];
};


export type MutationStartPaymentArgs = {
  method: Scalars['String'];
  reference: Scalars['String'];
  ovoId?: Maybe<Scalars['String']>;
};


export type MutationStartPayoutArgs = {
  amount: Scalars['Float'];
  accountId?: Maybe<Scalars['Int']>;
};


export type MutationUpdateMerchantDataArgs = {
  data: MerchantDataInput;
};


export type MutationCreatePaymentRequestArgs = {
  data: PaymentRequestInput;
};


export type MutationSetPaymentRequestFulfilledArgs = {
  paymentRequestId: Scalars['Int'];
};


export type MutationDeletePaymentRequestArgs = {
  paymentRequestId: Scalars['Int'];
};


export type MutationCreateClientArgs = {
  client: ClientDataInput;
};


export type MutationUpdateClientArgs = {
  clientId: Scalars['Int'];
  client: ClientDataInput;
};


export type MutationDeleteClientArgs = {
  clientId: Scalars['Int'];
};


export type MutationToggleFavoriteArgs = {
  clientId: Scalars['Int'];
  way?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateProductArgs = {
  product: ProductInput;
};


export type MutationUpdateProductArgs = {
  productId: Scalars['Int'];
  product: ProductInput;
};


export type MutationDeleteProductArgs = {
  productId: Scalars['Int'];
};


export type MutationCreatePayoutAccountArgs = {
  account: PayoutAccountInput;
};


export type MutationUpdatePayoutAccountArgs = {
  accountId: Scalars['Int'];
  account: PayoutAccountInput;
};


export type MutationDeletePayoutAccountArgs = {
  accountId: Scalars['Int'];
};


export type MutationSetDefaultPayoutAccountArgs = {
  accountId: Scalars['Int'];
  value?: Maybe<Scalars['Boolean']>;
};


export type MutationSetMerchantStatusArgs = {
  merchantId: Scalars['String'];
  status: Merchant_Status;
  keys: KeysInput;
};


export type MutationSetPayoutStatusArgs = {
  merchantId: Scalars['String'];
  success: Scalars['Boolean'];
  keys: KeysInput;
};


export type MutationAdminAuthArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationCreateExpenseArgs = {
  expense: ExpenseInput;
};


export type MutationUpdateExpenseArgs = {
  expenseId: Scalars['Int'];
  expense: ExpenseInput;
};


export type MutationDeleteExpenseArgs = {
  expenseId: Scalars['Int'];
};

export enum Payment_Type {
  Invoice = 'INVOICE',
  PaymentRequest = 'PAYMENT_REQUEST'
}

export enum Payout_Account_Type {
  Bank = 'BANK',
  Wallet = 'WALLET'
}

export enum Product_Type {
  Product = 'PRODUCT',
  Service = 'SERVICE'
}

export type PaymentRequest = {
  __typename?: 'PaymentRequest';
  id: Scalars['Int'];
  reference: Scalars['String'];
  name: Scalars['String'];
  email: Scalars['String'];
  amount: Scalars['Float'];
  fees: Scalars['Float'];
  user: User;
  transactions?: Maybe<Array<Maybe<PaymentServiceTransaction>>>;
  notice: Scalars['String'];
  isFullFilled: Scalars['Boolean'];
  clientId?: Maybe<Scalars['Int']>;
  client?: Maybe<Client>;
  includeFees: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PaymentRequestInput = {
  clientId: Scalars['Int'];
  amount?: Maybe<Scalars['Float']>;
  notice?: Maybe<Scalars['String']>;
  includeFees?: Maybe<Scalars['Boolean']>;
};

export type PaymentRequestResult = {
  __typename?: 'PaymentRequestResult';
  isSuccess: Scalars['Boolean'];
  paymentRequest?: Maybe<PaymentRequest>;
  error?: Maybe<Scalars['String']>;
};

export type PaymentServiceConfig = {
  __typename?: 'PaymentServiceConfig';
  cardPayment: Scalars['Float'];
  wallet: Scalars['Float'];
  alfamart: Scalars['Float'];
  virtualAccount: Scalars['Float'];
  ovo: Scalars['Float'];
  tax: Scalars['Float'];
  qris: Scalars['Float'];
  cr_blocked: Maybe<Scalars['Int']>;
  isInvoice: Scalars['Boolean'];
};

export type PaymentServiceTransaction = {
  __typename?: 'PaymentServiceTransaction';
  transaction_id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
};

export type PayoutAccount = {
  __typename?: 'PayoutAccount';
  id: Scalars['Int'];
  nickname: Scalars['String'];
  accountValue: Scalars['String'];
  bankId?: Maybe<Scalars['String']>;
  type: Payout_Account_Type;
  default: Scalars['Boolean'];
  user: User;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};

export type PayoutAccountInput = {
  nickname: Scalars['String'];
  accountValue: Scalars['String'];
  type: Payout_Account_Type;
  bankId?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['Boolean']>;
};

export type PayoutAccountResult = {
  __typename?: 'PayoutAccountResult';
  isSuccess: Scalars['Boolean'];
  account?: Maybe<PayoutAccount>;
  message?: Maybe<Scalars['String']>;
};

export type PayoutAccountsResult = {
  __typename?: 'PayoutAccountsResult';
  isSuccess: Scalars['Boolean'];
  accounts?: Maybe<Array<PayoutAccount>>;
  message?: Maybe<Scalars['String']>;
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['Int'];
  name: Scalars['String'];
  price: Scalars['Int'];
  costOfProductsSold: Scalars['Int'];
  type: Product_Type;
  quantityUnit: QuantityUnit;
  user: User;
  deleted: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};

export type ProductInput = {
  name: Scalars['String'];
  price: Scalars['Int'];
  costOfProductsSold?: Maybe<Scalars['Int']>;
  type: Product_Type;
  quantityUnit: QuantityUnitInput;
};

export type ProductResult = {
  __typename?: 'ProductResult';
  isSuccess: Scalars['Boolean'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  product?: Maybe<Product>;
  message?: Maybe<Scalars['String']>;
};

export type ProductsResult = {
  __typename?: 'ProductsResult';
  isSuccess: Scalars['Boolean'];
  products?: Maybe<Array<Product>>;
  message?: Maybe<Scalars['String']>;
};

export type Profit = {
  __typename?: 'Profit';
  grossProfit?: Maybe<Scalars['Float']>;
  revenue?: Maybe<Scalars['Float']>;
  costOfGoodsSold?: Maybe<Scalars['Float']>;
};

export type ProfitAmountQueryResult = {
  __typename?: 'ProfitAmountQueryResult';
  profitAmount?: Maybe<Scalars['Float']>;
  invProducts?: Maybe<Array<Maybe<InvoiceProduct>>>;
};

export type QuantityUnit = {
  __typename?: 'QuantityUnit';
  id: Scalars['Int'];
  name: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type QuantityUnitInput = {
  name: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  me: MePayload;
  compatible: CompatibleResult;
  profitAmount: ProfitAmountQueryResult;
  detailedReport: DetailedReportResult;
  getMerchant?: Maybe<Merchant>;
  getMerchantV2: GetMerchantResult;
  currencies?: Maybe<Array<Maybe<Currency>>>;
  getMerchantBalance: GetMerchantBalanceResult;
  getLastTransactions: GetLastTransactionsResult;
  getMerchantData: GetMerchantDataResult;
  getBanks: Array<Bank>;
  getPaymentConfig: PaymentServiceConfig;
  invoiceNumber: InvoiceNumberPayload;
  referenceValid: ReferenceValidPayload;
  getByReference: GetInvoiceByReferenceResult;
  getProducts: ProductsResult;
  getProduct: ProductResult;
  getPayoutAccounts: PayoutAccountsResult;
  getPayoutAccount: PayoutAccountResult;
  getClients: Array<Client>;
  getTransaction: GetTransactionResult;
  getVerificationByMerchant?: Maybe<Array<VerificationImage>>;
  getUsers: AdminGetUsersResponse;
  getInvoices: AdminGetInvoicesResponse;
  getPRs: AdminGetPRsResponse;
  getExpenses: ExpensesResult;
  getExpense: ExpenseResult;
  getImages: GetImagesResult;
};


export type QueryMeArgs = {
  language?: Maybe<Language>;
};


export type QueryDetailedReportArgs = {
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};


export type QueryGetLastTransactionsArgs = {
  last?: Maybe<Scalars['Int']>;
};


export type QueryReferenceValidArgs = {
  reference: Scalars['String'];
};


export type QueryGetByReferenceArgs = {
  reference: Scalars['String'];
};


export type QueryGetProductArgs = {
  productId: Scalars['Int'];
};


export type QueryGetPayoutAccountArgs = {
  accountId: Scalars['Int'];
};


export type QueryGetClientsArgs = {
  onlyFavorites?: Maybe<Scalars['Boolean']>;
};


export type QueryGetTransactionArgs = {
  reference: Scalars['String'];
};


export type QueryGetVerificationByMerchantArgs = {
  merchantId: Scalars['String'];
  keys: KeysInput;
};


export type QueryGetExpenseArgs = {
  expenseId: Scalars['Int'];
};


export type QueryGetImagesArgs = {
  ids: Array<Scalars['Int']>;
};

export enum Referral_Status {
  Sent = 'SENT',
  UserRegistered = 'USER_REGISTERED',
  SuccessfullReferral = 'SUCCESSFULL_REFERRAL'
}

export type ReferenceValidPayload = {
  __typename?: 'ReferenceValidPayload';
  isValid: Scalars['Boolean'];
};

export type Referral = {
  __typename?: 'Referral';
  id: Scalars['Int'];
  referredUser?: Maybe<ReferredUser>;
  referredBy?: Maybe<User>;
  status: Referral_Status;
  amountPaid: Scalars['Int'];
  userNotified: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};

export type ReferredUser = {
  __typename?: 'ReferredUser';
  fullName: Scalars['String'];
};

export type Result = {
  __typename?: 'Result';
  isSuccess: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export enum Social_Type {
  Apple = 'APPLE',
  Facebook = 'FACEBOOK',
  Google = 'GOOGLE'
}

export type StartPaymentResult = {
  __typename?: 'StartPaymentResult';
  isSuccess: Scalars['Boolean'];
  method: Scalars['String'];
  paymentReference: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  newUser: User;
};

export enum Transaction_Status {
  Unkown = 'UNKOWN',
  Inited = 'INITED',
  Successful = 'SUCCESSFUL',
  Failed = 'FAILED'
}

export enum Transaction_Type {
  Invoice = 'INVOICE',
  PaymentRequest = 'PAYMENT_REQUEST',
  Payout = 'PAYOUT',
  Refund = 'REFUND'
}

export type UpdateUserDataInput = {
  companyName?: Maybe<Scalars['String']>;
  companyAddress?: Maybe<Scalars['String']>;
  companyNumber?: Maybe<Scalars['String']>;
  companyWebsite?: Maybe<Scalars['String']>;
  companySocial?: Maybe<Scalars['String']>;
  companyEmailAdress?: Maybe<Scalars['String']>;
  companyDescription?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  currencyId?: Maybe<Scalars['Int']>;
  companyType?: Maybe<Company_Type>;
  fullName?: Maybe<Scalars['String']>;
  isFeeEnabled?: Maybe<Scalars['Boolean']>;
};


export type UploadImageResult = {
  __typename?: 'UploadImageResult';
  isSuccess: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  image?: Maybe<Image>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['Int'];
  email: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  socialId?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companyAddress?: Maybe<Scalars['String']>;
  companyNumber?: Maybe<Scalars['String']>;
  companyWebsite?: Maybe<Scalars['String']>;
  companySocial?: Maybe<Scalars['String']>;
  companyEmailAdress?: Maybe<Scalars['String']>;
  companyLogo?: Maybe<Scalars['String']>;
  language?: Maybe<Language>;
  referralId?: Maybe<Scalars['String']>;
  companyDescription?: Maybe<Scalars['String']>;
  currency?: Maybe<Currency>;
  isFeeEnabled?: Maybe<Scalars['Boolean']>;
  verifications?: Maybe<Array<Verification>>;
  companyType: Company_Type;
};

export enum Verification_Status {
  Initiated = 'INITIATED',
  Denied = 'DENIED',
  Approved = 'APPROVED'
}

export enum Voucher_Type {
  Receipt = 'RECEIPT',
  Invoice = 'INVOICE'
}

export type Verification = {
  __typename?: 'Verification';
  id: Scalars['ID'];
  user: User;
  images?: Maybe<Array<Maybe<VerificationImage>>>;
  status: Verification_Status;
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VerificationImage = {
  __typename?: 'VerificationImage';
  id: Scalars['ID'];
  key: Scalars['String'];
  url: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};

export type VerificationInput = {
  selfie?: Maybe<Scalars['String']>;
  idFront?: Maybe<Scalars['String']>;
  idBack?: Maybe<Scalars['String']>;
  taxFront?: Maybe<Scalars['String']>;
  taxBack?: Maybe<Scalars['String']>;
};

export type UserFragmentFragment = (
  { __typename?: 'User' }
  & Pick<User, 'email' | 'id'>
);

export type LogInMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LogInMutation = (
  { __typename?: 'Mutation' }
  & { logIn: (
    { __typename?: 'AuthPayload' }
    & Pick<AuthPayload, 'token' | 'tokenExpire'>
    & { user: (
      { __typename?: 'User' }
      & UserFragmentFragment
    ) }
  ) }
);

export type StartPaymentMutationVariables = Exact<{
  method: Scalars['String'];
  ovoId?: Maybe<Scalars['String']>;
  reference: Scalars['String'];
}>;


export type StartPaymentMutation = (
  { __typename?: 'Mutation' }
  & { startPayment: (
    { __typename?: 'StartPaymentResult' }
    & Pick<StartPaymentResult, 'isSuccess' | 'url' | 'method' | 'paymentReference' | 'error'>
  ) }
);

export type GetByReferenceQueryVariables = Exact<{
  reference: Scalars['String'];
}>;


export type GetByReferenceQuery = (
  { __typename?: 'Query' }
  & { getByReference: (
    { __typename?: 'GetInvoiceByReferenceResult' }
    & Pick<GetInvoiceByReferenceResult, 'isSuccess' | 'isFullFilled' | 'receiptUrl' | 'voucherType' | 'isVerified'>
    & { invoiceData?: Maybe<(
      { __typename?: 'InvoiceDataPayload' }
      & Pick<InvoiceDataPayload, 'invoiceUrl' | 'invoiceNumber' | 'totalAmount' | 'isFeeEnabled'>
    )>, companyData?: Maybe<(
      { __typename?: 'CompanyDataPayload' }
      & Pick<CompanyDataPayload, 'companyName' | 'companyLogo'>
    )> }
  ) }
);

export type GetPaymentConfigQueryVariables = Exact<{
  reference: Scalars['String'];
}>;


export type GetPaymentConfigQuery = (
  { __typename?: 'Query' }
  & { getPaymentConfig: (
    { __typename?: 'PaymentServiceConfig' }
    & Pick<PaymentServiceConfig, 'cardPayment' | 'wallet' | 'alfamart' | 'virtualAccount' | 'ovo' | 'tax' | 'qris' | 'cr_blocked' | 'isInvoice'>
  ) }
);

export type GetTransactionQueryVariables = Exact<{
  reference: Scalars['String'];
}>;


export type GetTransactionQuery = (
  { __typename?: 'Query' }
  & { getTransaction: (
    { __typename?: 'GetTransactionResult' }
    & Pick<GetTransactionResult, 'status' | 'receiptUrl' | 'entityType' | 'entityNumber' | 'entityUrl' | 'entityReference'>
  ) }
);

export const UserFragmentFragmentDoc = gql`
    fragment UserFragment on User {
  email
  id
}
    `;
export const LogInDocument = gql`
    mutation logIn($email: String!, $password: String!) {
  logIn(email: $email, password: $password) {
    user {
      ...UserFragment
    }
    token
    tokenExpire
  }
}
    ${UserFragmentFragmentDoc}`;
export type LogInMutationFn = Apollo.MutationFunction<LogInMutation, LogInMutationVariables>;

/**
 * __useLogInMutation__
 *
 * To run a mutation, you first call `useLogInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logInMutation, { data, loading, error }] = useLogInMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLogInMutation(baseOptions?: Apollo.MutationHookOptions<LogInMutation, LogInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogInMutation, LogInMutationVariables>(LogInDocument, options);
      }
export type LogInMutationHookResult = ReturnType<typeof useLogInMutation>;
export type LogInMutationResult = Apollo.MutationResult<LogInMutation>;
export type LogInMutationOptions = Apollo.BaseMutationOptions<LogInMutation, LogInMutationVariables>;
export const StartPaymentDocument = gql`
    mutation StartPayment($method: String!, $ovoId: String, $reference: String!) {
  startPayment(method: $method, ovoId: $ovoId, reference: $reference) {
    isSuccess
    url
    method
    paymentReference
    error
  }
}
    `;
export type StartPaymentMutationFn = Apollo.MutationFunction<StartPaymentMutation, StartPaymentMutationVariables>;

/**
 * __useStartPaymentMutation__
 *
 * To run a mutation, you first call `useStartPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startPaymentMutation, { data, loading, error }] = useStartPaymentMutation({
 *   variables: {
 *      method: // value for 'method'
 *      ovoId: // value for 'ovoId'
 *      reference: // value for 'reference'
 *   },
 * });
 */
export function useStartPaymentMutation(baseOptions?: Apollo.MutationHookOptions<StartPaymentMutation, StartPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartPaymentMutation, StartPaymentMutationVariables>(StartPaymentDocument, options);
      }
export type StartPaymentMutationHookResult = ReturnType<typeof useStartPaymentMutation>;
export type StartPaymentMutationResult = Apollo.MutationResult<StartPaymentMutation>;
export type StartPaymentMutationOptions = Apollo.BaseMutationOptions<StartPaymentMutation, StartPaymentMutationVariables>;
export const GetByReferenceDocument = gql`
    query getByReference($reference: String!) {
  getByReference(reference: $reference) {
    isSuccess
    isFullFilled
    receiptUrl
    voucherType
    isVerified
    invoiceData {
      invoiceUrl
      invoiceNumber
      totalAmount
      isFeeEnabled
    }
    companyData {
      companyName
      companyLogo
    }
  }
}
    `;

/**
 * __useGetByReferenceQuery__
 *
 * To run a query within a React component, call `useGetByReferenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetByReferenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetByReferenceQuery({
 *   variables: {
 *      reference: // value for 'reference'
 *   },
 * });
 */
export function useGetByReferenceQuery(baseOptions: Apollo.QueryHookOptions<GetByReferenceQuery, GetByReferenceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetByReferenceQuery, GetByReferenceQueryVariables>(GetByReferenceDocument, options);
      }
export function useGetByReferenceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetByReferenceQuery, GetByReferenceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetByReferenceQuery, GetByReferenceQueryVariables>(GetByReferenceDocument, options);
        }
export type GetByReferenceQueryHookResult = ReturnType<typeof useGetByReferenceQuery>;
export type GetByReferenceLazyQueryHookResult = ReturnType<typeof useGetByReferenceLazyQuery>;
export type GetByReferenceQueryResult = Apollo.QueryResult<GetByReferenceQuery, GetByReferenceQueryVariables>;
export const GetPaymentConfigDocument = gql`
    query GetPaymentConfig($reference: String!) {
  getPaymentConfig(reference: $reference) {
    cardPayment
    wallet
    alfamart
    virtualAccount
    ovo
    tax
    qris
    cr_blocked
  }
}
    `;

/**
 * __useGetPaymentConfigQuery__
 *
 * To run a query within a React component, call `useGetPaymentConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPaymentConfigQuery(baseOptions?: Apollo.QueryHookOptions<GetPaymentConfigQuery, GetPaymentConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentConfigQuery, GetPaymentConfigQueryVariables>(GetPaymentConfigDocument, options);
      }
export function useGetPaymentConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentConfigQuery, GetPaymentConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentConfigQuery, GetPaymentConfigQueryVariables>(GetPaymentConfigDocument, options);
        }
export type GetPaymentConfigQueryHookResult = ReturnType<typeof useGetPaymentConfigQuery>;
export type GetPaymentConfigLazyQueryHookResult = ReturnType<typeof useGetPaymentConfigLazyQuery>;
export type GetPaymentConfigQueryResult = Apollo.QueryResult<GetPaymentConfigQuery, GetPaymentConfigQueryVariables>;
export const GetTransactionDocument = gql`
    query getTransaction($reference: String!) {
  getTransaction(reference: $reference) {
    status
    receiptUrl
    entityType
    entityNumber
    entityUrl
    entityReference
  }
}
    `;

/**
 * __useGetTransactionQuery__
 *
 * To run a query within a React component, call `useGetTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionQuery({
 *   variables: {
 *      reference: // value for 'reference'
 *   },
 * });
 */
export function useGetTransactionQuery(baseOptions: Apollo.QueryHookOptions<GetTransactionQuery, GetTransactionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTransactionQuery, GetTransactionQueryVariables>(GetTransactionDocument, options);
      }
export function useGetTransactionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTransactionQuery, GetTransactionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTransactionQuery, GetTransactionQueryVariables>(GetTransactionDocument, options);
        }
export type GetTransactionQueryHookResult = ReturnType<typeof useGetTransactionQuery>;
export type GetTransactionLazyQueryHookResult = ReturnType<typeof useGetTransactionLazyQuery>;
export type GetTransactionQueryResult = Apollo.QueryResult<GetTransactionQuery, GetTransactionQueryVariables>;
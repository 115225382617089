import React from 'react';
import { Card, Grid, Typography, Button, makeStyles } from '@material-ui/core';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import checked from '../../assets/icons/checked.png';
import { useTranslate } from '../../hooks/useTranslate';
import { DotLoaderProvider } from '../ui/DotLoaderProvider';
import { GetByReferenceQuery, Voucher_Type } from '../../graphql';
export interface InvoiceHasBeenPaidProps {
  data: GetByReferenceQuery;
}

export const InvoiceHasBeenPaid: React.FC<InvoiceHasBeenPaidProps> = ({ data }) => {
  const [translate] = useTranslate();
  const classes = useStyles();
  return (
    <Grid container className={classes.container} justify="center" alignItems="center">
      <Card style={{ padding: 12 }}>
        <Grid container justify="center" alignItems="center">
          <img
            alt="success"
            src={checked}
            style={{
              height: 100,
              marginRight: 24,
            }}
          />
          <Typography style={{ textAlign: 'center' }} component="h5" align="left" variant="h5">
            {translate('pages.login.invoicePaid')}
          </Typography>
          <hr />
        </Grid>
        {data.getByReference && data.getByReference.receiptUrl === '' && (
          <Grid style={{ paddingTop: 24 }} item xs={12}>
            <DotLoaderProvider inside />
            <Typography style={{ textAlign: 'center' }} component="h6" align="left" variant="h6">
              {translate('pages.login.waitForResponse')}
            </Typography>
          </Grid>
        )}
        {data.getByReference.receiptUrl && data.getByReference.receiptUrl !== '' && (
          <Grid style={{ paddingTop: 24 }} item xs={12}>
            <Grid container justify="space-between">
              <Grid item xs={4}>
                <Button
                  href={data?.getByReference.receiptUrl || ''}
                  download
                  target="_blank"
                  variant="contained"
                  color="default"
                  size="small"
                  startIcon={<ArrowDownward />}
                >
                  {translate('pages.login.downloadReceipt')}
                </Button>
              </Grid>
              {data.getByReference.voucherType === Voucher_Type.Invoice && (
                <Grid item xs={4} style={{ textAlign: 'right' }}>
                  <Button
                    href={data?.getByReference.invoiceData?.invoiceUrl || ''}
                    download
                    target="_blank"
                    variant="contained"
                    color="default"
                    size="small"
                    startIcon={<ArrowDownward />}
                  >
                    {translate('pages.login.downloadInvoice')}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Card>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      height: '100vh',
    },
    background: '#f1f1f1',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

import { useStoreState } from '../stores';
import { translate } from '../utils/Translate';

export const useTranslate = () => {
  const lang = useStoreState((state) => state.locale.data.lang);
  const translateHook = (wordId: string): string => {
    return translate(wordId, lang);
  };

  return [translateHook];
};

import React from 'react';
import { Card, Grid, Typography } from '@material-ui/core';
import error from '../../assets/icons/error.png';
import { useTranslate } from '../../hooks/useTranslate';

export const PaymentFailed: React.FC = () => {
  const [translate] = useTranslate();
  return (
    <Card style={{ padding: 12, marginBottom: 12 }}>
      <Grid container justify="center" alignItems="center">
        <img
          alt="success"
          src={error}
          style={{
            height: 80,
            marginRight: 24,
          }}
        />
        <Typography style={{ textAlign: 'center' }} component="h6" align="left" variant="h6">
          {translate('pages.login.paymentUnsuccessful')}
        </Typography>
      </Grid>
    </Card>
  );
};

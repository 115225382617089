import React from 'react';
import { DotLoader } from 'react-spinners';
import { css } from '@emotion/core';
import { Grid, makeStyles } from '@material-ui/core';

const override = css`
  display: block;
  margin: auto auto;
  border-color: red;
`;

export interface DotLoaderProviderConst {
  inside?: boolean;
}

export const DotLoaderProvider: React.FC<DotLoaderProviderConst> = ({ inside = false }) => {
  const classes = useStyles();
  if (inside) {
    return (
      <Grid item xs={12}>
        <DotLoader size={70} css={override} color={'#eb2728'} />
      </Grid>
    );
  }
  return (
    <Grid className={classes.container} container>
      <Grid item xs={12}>
        <DotLoader size={70} css={override} color={'#eb2728'} />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

import { Fix_Type } from '../graphql';
export const formatAmount = (
  amount: number,
  short: string,
  fix: Fix_Type,
  separator: string,
  decimalSep: string,
  decimals: number,
) => {
  return `${fix === Fix_Type.Prefix ? short + ' ' : ''}${amount
    .toFixed(decimals)
    .replace('.', decimalSep)
    .replace(/\B(?=(\d{3})+(?!\d))/g, separator || ',')
    .replace('.00', '')}${fix === Fix_Type.Suffix ? short + ' ' : ''}`;
};

import React, { useEffect } from 'react';
import { Box, Button, Card, Grid, makeStyles } from '@material-ui/core';
import Security from '@material-ui/icons/Security';
import { Payment_Type, Transaction_Status, useGetTransactionLazyQuery } from '../../graphql';
import { useHistory, useParams } from 'react-router-dom';
import { DotLoaderProvider } from '../../components/ui/DotLoaderProvider';
import { setTimeout } from 'timers';
import successImage from '../../assets/icons/checked.png';
import errorImage from '../../assets/icons/error.png';

export interface TransactionPageReference {
  reference: string;
}

let timeout: NodeJS.Timeout | null = null;

export const TransactionStatusPage: React.FC = () => {
  const classes = useStyles();

  let { reference } = useParams<TransactionPageReference>();
  const history = useHistory();

  const [getTransaction, { loading, data, error }] = useGetTransactionLazyQuery();

  useEffect(() => {
    getTransaction({ variables: { reference } });
  }, [getTransaction, reference]);

  useEffect(() => {
    if (data && data.getTransaction.status === Transaction_Status.Inited && timeout === null) {
      timeout = setTimeout(() => {
        history.go(0);
      }, 15000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (loading) {
    return (
      <Grid container className={classes.container} justify="center" alignItems="center">
        <DotLoaderProvider />
      </Grid>
    );
  }

  if (error) {
    return (
      <Grid container className={classes.container} justify="center" alignItems="center">
        <Card style={{ padding: 12 }}>Transaction request error</Card>
      </Grid>
    );
  }

  if (!data) return null;

  return (
    <Grid container className={classes.container} justify="center" alignItems="center">
      <Card style={{ padding: 12, minWidth: 320 }}>
        {(data.getTransaction.status === Transaction_Status.Successful ||
          data.getTransaction.status === Transaction_Status.Failed) && (
          <Box mt={2} mb={2}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={3}>
                <img
                  alt="success"
                  src={data.getTransaction.status === Transaction_Status.Successful ? successImage : errorImage}
                  style={{
                    height: 70,
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        )}

        {data.getTransaction.status === Transaction_Status.Inited && (
          <Box mt={4} mb={4}>
            <Grid container>
              <Grid item xs={12}>
                <DotLoaderProvider inside={true} />
              </Grid>
            </Grid>
          </Box>
        )}

        <Box>
          <Grid container>
            <Grid item xs={6}>
              Transaction status
            </Grid>
            <Grid item xs={6}>
              {data.getTransaction.status === Transaction_Status.Inited
                ? 'Waiting for the result'
                : data.getTransaction.status}
            </Grid>
          </Grid>
        </Box>

        <Box mt={2}>
          <Grid container>
            <Grid item xs={6}>
              {data.getTransaction.entityType === Payment_Type.Invoice ? 'Invoice number' : 'Payment note'}
            </Grid>
            <Grid item xs={6}>
              {data.getTransaction.entityNumber}
            </Grid>
          </Grid>
        </Box>

        <Box mt={2}>
          <Grid container alignItems="center">
            <Grid item xs={6}>
              Receipt
            </Grid>
            <Grid item xs={6}>
              {data.getTransaction.receiptUrl && (
                <a
                  href={data.getTransaction.receiptUrl || ''}
                  download
                  target={'_blank'}
                  rel="noopener noreferrer"
                  style={{ textTransform: 'none', textDecoration: 'none' }}
                >
                  Click to download
                </a>
              )}
              {!data.getTransaction.receiptUrl && <>Not available</>}
            </Grid>
          </Grid>
        </Box>

        {data.getTransaction.entityType === Payment_Type.Invoice && (
          <Box mt={2}>
            <Grid container alignItems="center">
              <Grid item xs={6}>
                Invoice
              </Grid>
              <Grid item xs={6}>
                {data.getTransaction.entityUrl && (
                  <a
                    href={data.getTransaction.entityUrl || ''}
                    download
                    target={'_blank'}
                    rel="noopener noreferrer"
                    style={{ textTransform: 'none', textDecoration: 'none' }}
                  >
                    Click to download
                  </a>
                )}
                {!data.getTransaction.entityUrl && <>Not available</>}
              </Grid>
            </Grid>
          </Box>
        )}

        {data.getTransaction.status === Transaction_Status.Failed && (
          <Box mt={4}>
            <Grid container>
              <Grid item xs={12}>
                <Button
                  onClick={() => {
                    history.push(`/${data.getTransaction.entityReference}`);
                  }}
                  fullWidth
                  disabled={false}
                  variant="contained"
                  style={{ backgroundColor: '#26bd00', color: '#fff' }}
                  size="medium"
                  startIcon={<Security />}
                >
                  Retry
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </Card>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      height: '100vh',
    },
    background: '#f1f1f1',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

import { ApolloClient, InMemoryCache, concat, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { getConfig } from '../utils/Config';

export const useApollo = () => {
  const asyncAuthLink = setContext(
    () =>
      new Promise(async (success) => {
        //get token ASYNC
        const token = 'aaaa';
        console.log('getToken', token);
        success({ headers: { authorization: token } });
      }),
  );

  const httplink = new HttpLink({
    uri: getConfig().url,
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
      );

    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  const getApolloConfig = () => {
    return new ApolloClient({
      cache: new InMemoryCache(),
      link: concat(httplink, concat(errorLink, asyncAuthLink)),
    });
  };

  return [getApolloConfig];
};

import { Card, Grid, makeStyles, Typography, Button } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Security from '@material-ui/icons/Security';
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import {
  Fix_Type,
  useGetByReferenceQuery,
  useGetPaymentConfigQuery,
  useStartPaymentMutation,
  Voucher_Type,
} from '../../graphql';
import creditcard from '../../assets/icons/creditcard.png';
import dokulogo from '../../assets/icons/dokulogo.png';
import ovologo from '../../assets/icons/ovologo.jpg';
import shopeepaylogo from '../../assets/icons/shopeepaylogo.jpg';
import qrislogo from '../../assets/icons/qris.png';

import { DotLoaderProvider } from '../ui/DotLoaderProvider';
import { formatAmount } from '../../utils/Amount';
import { InvoiceHasBeenPaid } from './InvoiceHasBeenPaid';
import { PaymentFailed } from './PaymentFailed';
import { PaymentMethod, PaymentMethodProps } from './PaymentMethod';
import { useStoreState } from '../../stores';
import { useStoreActions } from '../../stores';
import { useTranslate } from '../../hooks/useTranslate';

export interface LoginLayoutReference {
  reference: string;
  language: string;
  status: string;
}

export interface PaymentState {
  amount: number;
  paymentMethod: string;
  internetAccountWait: boolean;
  internetAccountCode: string;
  ovoPayment: boolean;
  paymentDetail: string;
}

export const LoginLayout: React.FC<{}> = () => {
  const classes = useStyles();
  const history = useHistory();

  let { reference, language, status } = useParams<LoginLayoutReference>();

  //get reference status
  let { data, loading, refetch } = useGetByReferenceQuery({
    variables: {
      reference: reference,
    },
    fetchPolicy: 'network-only',
  });

  const [paymentState, setPaymentState] = useState<PaymentState>({
    amount: 0,
    paymentMethod: 'QRIS',
    internetAccountWait: false,
    internetAccountCode: '',
    ovoPayment: false,
    paymentDetail: '{}',
  });

  const [ovoID, setOVOID] = useState<string>('');
  const [virtualId, setVirtualId] = useState<string>('0');
  // const paymentConfig = useGetPaymentConfigQuery();

  const paymentConfig = useGetPaymentConfigQuery({
    variables: {
      reference: reference,
    },
    fetchPolicy: 'network-only',
  });

  const setPaymentMethod = (id: string) => {
    setPaymentState({
      ...paymentState,
      paymentMethod: id,
    });
  };

  let paymentMethods: PaymentMethodProps[] = [
    {
      id: 'QRIS',
      name: 'QRIS',
      image: qrislogo,
      onClick: setPaymentMethod,
      setOVOID,
      ovoID,
      setVirtualId,
      virtualId,
    },
    {
      id: 'CREDITCARD',
      name: 'Credit Card',
      image: creditcard,
      onClick: setPaymentMethod,
      setOVOID,
      ovoID,
      setVirtualId,
      virtualId,
    },
    {
      id: 'VA',
      name: 'Virtual Account',
      image: dokulogo,
      onClick: setPaymentMethod,
      setOVOID,
      ovoID,
      setVirtualId,
      virtualId,
    },
    {
      id: 'OVO',
      name: 'OVO Payment',
      image: ovologo,
      onClick: setPaymentMethod,
      setOVOID,
      ovoID,
      setVirtualId,
      virtualId,
    },
    {
      id: 'SHOPEEPAY',
      name: 'SHOPEEPAY Payment',
      image: shopeepaylogo,
      onClick: setPaymentMethod,
      setOVOID,
      ovoID,
      setVirtualId,
      virtualId,
    },
  ];

  if (paymentConfig.data?.getPaymentConfig.cr_blocked === 0) {
    paymentMethods = paymentMethods.filter((data: PaymentMethodProps) => data.id !== 'CREDITCARD');
  }

  const lang = useStoreState((state) => state.locale.data.lang);
  const setLang = useStoreActions((actions) => actions.locale.update);

  if (language && lang !== language) {
    setLang({ lang: language });
  }

  const [translate] = useTranslate();

  const [startPayment, startPaymentState] = useStartPaymentMutation({
    onCompleted: (res) => {
      if (!res.startPayment) return;
      if (!res.startPayment.url) return;

      if (paymentState.paymentMethod === 'CREDITCARD' || paymentState.paymentMethod === 'QRIS') {
        document.open();
        document.write(res.startPayment.url);
        document.close();
      } else if (paymentState.paymentMethod === 'OVO') {
        history.push(`/status/${res.startPayment.paymentReference}`);
        return;
      } else if (paymentState.paymentMethod.includes('VA') || paymentState.paymentMethod === 'SHOPEEPAY') {
        document.location.href = res.startPayment.url;
      }
    },
    onError: (err) => {
      console.error(err);
    },
  });

  if (
    data &&
    data.getByReference &&
    data.getByReference.invoiceData &&
    data.getByReference.invoiceData.totalAmount &&
    data.getByReference.invoiceData.totalAmount > 0 &&
    paymentState.amount === 0
  ) {
    setPaymentState({
      ...paymentState,
      amount: data.getByReference.invoiceData.totalAmount,
    });
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (data && data.getByReference && data.getByReference.receiptUrl === '' && status && status === 'success') {
        if (refetch) {
          console.log('refetch');
          refetch();
        }
      } else {
        clearInterval(interval);
      }
    }, 4000);
    return () => {
      clearInterval(interval);
    };
  }, [data, refetch, status]);

  if (loading) {
    return <DotLoaderProvider />;
  }

  if (!data || data.getByReference.isSuccess === false) {
    return <div>wrong reference...</div>;
  }

  let isPaid = (status && status === 'success') || data?.getByReference.isFullFilled === true;

  if (data && isPaid) {
    return <InvoiceHasBeenPaid data={data} />;
  }

  const amount = (amount: number, fee: number) => {
    return formatAmount(amount + fee, 'Rp.', Fix_Type.Prefix, ',', '.', 0);
  };

  const calculateFee = (amount: number) => {
    if (paymentConfig && paymentConfig.data && paymentConfig.data.getPaymentConfig) {
      let config = paymentConfig.data.getPaymentConfig;
      
      if (paymentState.paymentMethod.includes('VA')) {
        // return config.virtualAccount + config.virtualAccount * (config.tax / 100);
        return config.virtualAccount + (config.virtualAccount * config.tax / 100);
      }

      switch (paymentState.paymentMethod) {
        case 'CREDITCARD':
          // return amount * (config.cardPayment / 100) * (1 + config.tax / 100);
          const card: number = (amount * config.cardPayment / 100) + 1500;
          return card + (card * config.tax / 100);
        case 'OVO':
          // return amount * (config.wallet / 100) * (1 + config.tax / 100);
          const ovo: number = (amount * config.wallet / 100);
          return ovo + (ovo * config.tax / 100);
        case 'SHOPEEPAY':
          const shopee: number = (amount * config.wallet / 100);
          return shopee + (shopee * config.tax / 100);
        case 'QRIS':
          const qr: number = (amount * config.qris / 100);
          return qr + (qr * config.tax / 100);

        default:
          return 0;
      }
    } else {
      return 0;
    }
  };

  const checkIfDisabled = (): boolean => {
    if (paymentState.paymentMethod === 'VA' && virtualId === '0') {
      return true;
    }

    if (paymentState.paymentMethod === 'OVO' && ovoID.length === 0) {
      return true;
    }

    return false;
  };

  const getPaymentMethod = () => {
    if (paymentState.paymentMethod === 'VA') {
      return virtualId;
    }

    return paymentState.paymentMethod;
  };

  return (
    <Grid container className={classes.container} justify="center" alignItems="center">
      {data.getByReference.isVerified && (
        <Grid style={{ padding: 12 }} item lg={4} sm={6} xs={12}>
          {status && status === 'failed' && <PaymentFailed />}
          {startPaymentState.loading && <DotLoaderProvider />}

          {!isPaid &&
            !paymentState.ovoPayment &&
            !startPaymentState.loading &&
            data?.getByReference.isFullFilled === false && (
              <Card style={{ padding: 12 }}>
                <Grid container justify="center" alignItems="center">
                  <Grid item xs={12}>
                    <Typography component="h6" align="center" variant="h4">
                      {amount(
                        data?.getByReference.invoiceData?.totalAmount || 0,
                        (
                          data?.getByReference.invoiceData?.totalAmount && data.getByReference.isVerified && data.getByReference.invoiceData.isFeeEnabled
                          ? calculateFee(data.getByReference.invoiceData.totalAmount || 0)
                          : 0
                        ),
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={1} style={{ paddingTop: 24 }} justify="center" alignItems="center">
                  <Grid item xs={12}>
                    <Typography style={{ paddingBottom: 12 }} align="left" variant="h6" component="h6">
                      {translate('pages.login.choosePaymentMethod')}
                    </Typography>
                  </Grid>
                  {paymentMethods.map((method) => (
                    <PaymentMethod key={method.id} isSelected={method.id === paymentState.paymentMethod} {...method} />
                  ))}
                </Grid>
                <Grid container style={{ paddingTop: 42 }} justify="center" alignItems="center">
                  <Button
                    onClick={() => {
                      startPayment({
                        variables: {
                          reference,
                          ovoId: ovoID,
                          method: getPaymentMethod(),
                        },
                      });
                    }}
                    fullWidth
                    disabled={checkIfDisabled()}
                    variant="contained"
                    style={{ backgroundColor: checkIfDisabled() ? 'gray' : '#26bd00', color: '#fff' }}
                    size="medium"
                    startIcon={<Security />}
                  >
                    {amount(
                      data?.getByReference.invoiceData?.totalAmount || 0,
                      (
                        data?.getByReference.invoiceData?.totalAmount && data.getByReference.isVerified && data.getByReference.invoiceData.isFeeEnabled
                        ? calculateFee(data.getByReference.invoiceData.totalAmount || 0)
                        : 0
                      ),
                    )}
                  </Button>
                </Grid>
              </Card>
            )}
        </Grid>
      )}
      <Grid style={{ padding: 12 }} item lg={3} sm={5} xs={12}>
        <Card style={{ padding: 12 }}>
          <Grid container justify="flex-start" alignItems="flex-start">
            <Grid item>
              {data?.getByReference.companyData?.companyLogo && (
                <img src={data?.getByReference.companyData?.companyLogo} alt="logo" className={classes.logoImage} />
              )}
              <Typography align="left" variant="h6" component="h6">
                {data?.getByReference.companyData?.companyName || ''}
              </Typography>
            </Grid>
            <Grid style={{ paddingTop: 12 }} item xs={12}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography component="div" align="left" variant="body1">
                    {data.getByReference.voucherType === Voucher_Type.Invoice ? 'Invoice number' : 'Reference'}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography component="div" align="right" variant="body1">
                    {data?.getByReference.invoiceData?.invoiceNumber}
                  </Typography>
                </Grid>
                {paymentConfig && paymentConfig.data && paymentConfig.data.getPaymentConfig && (
                  <>
                    <Grid item xs={6}>
                      <Typography component="div" align="left" variant="body1">
                        {translate('pages.login.invoiceTotal')}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography component="div" align="right" variant="body1">
                        {formatAmount(
                          data?.getByReference.invoiceData?.totalAmount || 0,
                          'Rp.',
                          Fix_Type.Prefix,
                          ',',
                          '.',
                          0,
                        )}
                      </Typography>
                    </Grid>
                  </>
                )}
                {
                  paymentConfig
                  && paymentConfig.data
                  && paymentConfig.data.getPaymentConfig
                  && data.getByReference.isVerified
                  && data.getByReference.invoiceData?.isFeeEnabled
                  && (
                  <>
                    <Grid item xs={6}>
                      <Typography component="div" align="left" variant="body1">
                        Processing fee
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography component="div" align="right" variant="body1">
                        {formatAmount(
                          calculateFee(data?.getByReference.invoiceData?.totalAmount || 0),
                          'Rp.',
                          Fix_Type.Prefix,
                          ',',
                          '.',
                          0,
                        )}
                      </Typography>
                    </Grid>
                  </>
                )}
                <Grid item xs={6}>
                  <Typography style={{ fontWeight: 800 }} component="div" align="left" variant="body1">
                    {translate('pages.login.total')}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography style={{ fontWeight: 800 }} component="div" align="right" variant="body1">
                    {amount(
                      data?.getByReference.invoiceData?.totalAmount || 0,
                      (
                        data?.getByReference.invoiceData?.totalAmount && data.getByReference.isVerified && data.getByReference.invoiceData.isFeeEnabled
                        ? calculateFee(data.getByReference.invoiceData.totalAmount || 0)
                        : 0
                      ),
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {data.getByReference.voucherType === Voucher_Type.Invoice && (
              <Grid style={{ paddingTop: 24 }} item xs={12}>
                <Grid container>
                  <Grid item xs={6}>
                    <Button
                      href={data?.getByReference.invoiceData?.invoiceUrl || ''}
                      download
                      target="_blank"
                      variant="contained"
                      color="default"
                      size="small"
                      startIcon={<LinkIcon />}
                    >
                      {translate('pages.login.viewInvoice')}
                    </Button>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: 'right' }}>
                    <Button
                      href={data?.getByReference.invoiceData?.invoiceUrl || ''}
                      download
                      target="_blank"
                      variant="contained"
                      color="default"
                      size="small"
                      startIcon={<ArrowDownward />}
                    >
                      {translate('pages.login.downloadInv')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    [theme.breakpoints.up('sm')]: {
      height: '100vh',
    },
    //background: 'rgb(251,251,251)',
    background: '#f1f1f1',
    //filter: 'progid:DXImageTransform.Microsoft.gradient(startColorstr="#b2aef2",endColorstr="#00d4ff",GradientType=1)',
    justify: 'center',
    alignItems: 'center',
  },
  logoImage: {
    width: 150,
    minHeight: 70,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  selected: {
    padding: 2,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000',
    backgroundColor: '#ececec',
  },
  nonSelected: {
    padding: 2,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#fff',
  },
}));
